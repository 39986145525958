<script setup>
import {
  userProfile,
  userDataLoading,
  countyIsKirinyaga,
  countyIsKilifi,
  useRouterFromInstance,
  shouldShowUserData,
  setShouldShowUserData,
} from "@/utils/vue_helpers.js";
import SkeletonLoader from "@/components/utils/SkeletonLoader";
import { routes } from "@/router/routes";
import { computed } from "vue";

const { route } = useRouterFromInstance();

const isVisible = computed(() => {
  return route.value.name === routes.userAccountDetails.name;
});

const avatarWidth = 96;

const showOrHideDetailsLabel = computed(() => {
  if (shouldShowUserData.value === true) {
    return "Hide My Details";
  }
  return "Show My Details";
});
</script>

<template>
  <v-card
    v-if="isVisible"
    :class="{
      'reduced-opacity-background': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-container fluid class="pl-6">
      <v-row align="center" justify="center" justify-md="start">
        <v-col
          cols="auto"
          md="auto"
          :style="{
            filter: shouldShowUserData ? null : 'blur(8px)',
          }"
        >
          <SkeletonLoader
            :loading="userDataLoading"
            type="image"
            :width="avatarWidth"
            :height="avatarWidth"
          >
            <v-avatar :size="avatarWidth">
              <v-img
                :width="avatarWidth"
                cover
                :src="require('@/assets/images/default_pic.png')"
              />
            </v-avatar>
          </SkeletonLoader>
        </v-col>

        <v-col
          cols="12"
          md="auto"
          class="d-flex flex-column align-center align-md-start justify-center ml-md-4"
          :style="{
            filter: shouldShowUserData ? null : 'blur(4px)',
          }"
        >
          <SkeletonLoader
            :loading="userDataLoading"
            type="text"
            height="40"
            width="300"
          >
            <div class="text-h5">{{ userProfile.customer_name }}</div>
          </SkeletonLoader>

          <template v-if="!countyIsKilifi">
            <SkeletonLoader
              :loading="userDataLoading"
              type="text"
              height="32"
              width="200"
              class="mt-2"
            >
              <div class="text-h6">{{ userProfile.email_address }}</div>
            </SkeletonLoader>

            <SkeletonLoader
              v-if="countyIsKirinyaga"
              :loading="userDataLoading"
              type="text"
              height="32"
              width="200"
              class="mt-2"
            >
              <div class="text-h6">{{ userProfile.phone_number1 }}</div>
            </SkeletonLoader>
          </template>
        </v-col>

        <v-spacer />

        <v-col cols="auto" class="align-self-stretch d-flex align-start">
          <v-switch
            class="mt-0"
            color="primary"
            :input-value="shouldShowUserData"
            @change="setShouldShowUserData($event)"
            :label="showOrHideDetailsLabel"
          >
          </v-switch>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
