<script setup>
import { fetchBusinessFormDataOnMount } from "@/utils/vue_helpers";
import { getDashboardLinks } from "@/router/dashboard_links";
import SideMenu from "../user/SideMenu.vue";
import { useQueryClient } from "@tanstack/vue-query";
import Header from "../user/Header.vue";
import GenericBackground from "./GenericBackground.vue";

const props = defineProps({
  links: {
    type: Array,
    default: null,
  },
});

fetchBusinessFormDataOnMount();

const queryClient = useQueryClient();
queryClient.invalidateQueries({ queryKey: ["visitorData"] });
</script>

<template>
  <GenericBackground>
    <v-container fluid class="pa-0 pa-md-3">
      <v-row no-gutters>
        <v-col cols="12">
          <Header />
        </v-col>
      </v-row>

      <v-row :no-gutters="$mobileBreakpoint">
        <v-col cols="12">
          <v-card
            :tile="$mobileBreakpoint"
            :flat="$mobileBreakpoint"
            :class="{
              'reduced-opacity-background': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <div class="d-flex align-stretch">
              <SideMenu
                v-if="!$mobileBreakpoint"
                :links="links"
              />

              <div class="flex-grow-1 pl-3 py-2 pr-3" :style="{ minWidth: 0 }">
                <router-view> </router-view>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </GenericBackground>
</template>
