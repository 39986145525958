var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isVisible)?_c('v-card',{class:{
    'reduced-opacity-background': _vm.$vuetify.breakpoint.mdAndUp,
  }},[_c('v-container',{staticClass:"pl-6",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","justify-md":"start"}},[_c('v-col',{style:({
          filter: _setup.shouldShowUserData ? null : 'blur(8px)',
        }),attrs:{"cols":"auto","md":"auto"}},[_c(_setup.SkeletonLoader,{attrs:{"loading":_setup.userDataLoading,"type":"image","width":_setup.avatarWidth,"height":_setup.avatarWidth}},[_c('v-avatar',{attrs:{"size":_setup.avatarWidth}},[_c('v-img',{attrs:{"width":_setup.avatarWidth,"cover":"","src":require('@/assets/images/default_pic.png')}})],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-column align-center align-md-start justify-center ml-md-4",style:({
          filter: _setup.shouldShowUserData ? null : 'blur(4px)',
        }),attrs:{"cols":"12","md":"auto"}},[_c(_setup.SkeletonLoader,{attrs:{"loading":_setup.userDataLoading,"type":"text","height":"40","width":"300"}},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_setup.userProfile.customer_name))])]),(!_setup.countyIsKilifi)?[_c(_setup.SkeletonLoader,{staticClass:"mt-2",attrs:{"loading":_setup.userDataLoading,"type":"text","height":"32","width":"200"}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_setup.userProfile.email_address))])]),(_setup.countyIsKirinyaga)?_c(_setup.SkeletonLoader,{staticClass:"mt-2",attrs:{"loading":_setup.userDataLoading,"type":"text","height":"32","width":"200"}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_setup.userProfile.phone_number1))])]):_vm._e()]:_vm._e()],2),_c('v-spacer'),_c('v-col',{staticClass:"align-self-stretch d-flex align-start",attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"primary","input-value":_setup.shouldShowUserData,"label":_setup.showOrHideDetailsLabel},on:{"change":function($event){return _setup.setShouldShowUserData($event)}}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }